import { ProjectData } from 'shared/types/projects'

export const isValidProjectData = (data: ProjectData) => {
  return (
    typeof data === 'object' &&
    'screenFragments' in data &&
    'flow' in data &&
    'roles' in data &&
    Object.keys(data.screenFragments).length !== 0
  )
}

export const getTimestamp = () => Number(new Date())

export const getProjectKey = (projectId: string) => `PROJECT-${projectId}`

export const getProjectType = () => {
  const url = window.location.href

  const isMaster =
    url.includes('/aa/') || url.includes('/rr/') || url.includes('/rrt/')
  const isChild = url.includes('/aaa/') || url.includes('/rrr/')

  if (isMaster) return 'master'

  if (isChild) return 'child'

  return null
}
