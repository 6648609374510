import {
  FlowContainerNodeProps,
  useDeleteColumn,
  useDeleteRow,
} from 'entities/flow'
import { MouseEventHandler, useState } from 'react'
import classNames from 'classnames'
import { IconButton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { ReactFlowState, useStore } from 'reactflow'

import styles from './FlowContainerNode.module.css'
import { useHasWritePermission } from 'entities/permissions'
import i18n from 'shared/i18n/i18n'
import { useProjectContext } from 'features/head/context'

const zoomSelector = (state: ReactFlowState) => state.transform[2]

const ICON_MIN_SIZE = 30

export const FlowContainerNode = ({
  data: { subType },
  yPos,
  xPos,
  id,
}: FlowContainerNodeProps) => {
  const hasWritePermission = useHasWritePermission()

  const zoom = useStore(zoomSelector)

  const [focused, setFocused] = useState(false)
  const isColumn = subType === 'column'

  const calculatedIconSize = Math.round(15 / zoom)
  const iconSize =
    calculatedIconSize >= ICON_MIN_SIZE ? calculatedIconSize : ICON_MIN_SIZE

  const buttonTitle = `${i18n.t('remove')} ${
    isColumn ? i18n.t('column') : i18n.t('row')
  }`

  const deleteRow = useDeleteRow()
  const deleteColumn = useDeleteColumn()

  const buttonClickHandle: MouseEventHandler = (e) => {
    isColumn
      ? deleteColumn({
          positionX: xPos,
        })
      : deleteRow({
          positionY: yPos,
        })
  }

  const { isMaster } = useProjectContext()

  return (
    <div
      id={id}
      onClick={() =>
        hasWritePermission && isMaster ? setFocused(true) : undefined
      }
      onBlur={(e) => {
        if (
          !e.relatedTarget ||
          (e.relatedTarget.id !== id && e.relatedTarget.tagName !== 'BUTTON')
        ) {
          setFocused(false)
        }
      }}
      tabIndex={0}
      className={classNames(styles.wrapper, {
        [styles.focused]: focused,
      })}
    >
      {focused && (
        <>
          <IconButton
            className={isColumn ? styles.topButton : styles.leftButton}
            onClick={buttonClickHandle}
            title={buttonTitle}
          >
            <DeleteIcon
              style={{
                height: iconSize,
                width: iconSize,
              }}
            />
          </IconButton>
          <IconButton
            className={isColumn ? styles.bottomButton : styles.rightButton}
            onClick={buttonClickHandle}
            title={buttonTitle}
          >
            <DeleteIcon
              style={{
                height: iconSize,
                width: iconSize,
              }}
            />
          </IconButton>
        </>
      )}
    </div>
  )
}
