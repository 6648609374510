import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { Flow } from './flow'
import { Assessment } from './assessment'
import { ReportPage } from 'widgets/report/ui/ReportPage'

const router = createBrowserRouter([
  {
    path: '/a/:projectId',
    element: <Flow />,
  },
  {
    path: '/a/:projectId/screen/:screenId',
    element: <Assessment />,
  },
  {
    path: '/aa/:projectId',
    element: <Flow />,
  },
  {
    path: '/aa/:projectId/screen/:screenId',
    element: <Assessment />,
  },
  {
    path: '/aaa/:projectId',
    element: <Flow />,
  },
  {
    path: '/aaa/:projectId/screen/:screenId',
    element: <Assessment />,
  },
  {
    path: '/project/:projectId',
    element: <Flow />,
  },
  {
    path: '/project/:projectId/screen/:screenId',
    element: <Assessment />,
  },
  {
    path: '/r/:projectId',
    element: <ReportPage />,
  },
  {
    path: '/r/:screenNumber/:projectId',
    element: <ReportPage />,
  },
  {
    path: '/r/:screenNumber/:screenVariantNumber/:projectId',
    element: <ReportPage />,
  },
  {
    path: '/r/:screenNumber/:screenVariantNumber/:screenVariantFragmentNumber/:projectId',
    element: <ReportPage />,
  },
  {
    path: '/r/:screenNumber/:screenVariantNumber/:screenVariantFragmentNumber/:assessmentId/:projectId',
    element: <ReportPage />,
  },
  {
    path: '/rr/:projectId',
    element: <ReportPage />,
  },
  {
    path: '/rr/:screenNumber/:projectId',
    element: <ReportPage />,
  },
  {
    path: '/rr/:screenNumber/:screenVariantNumber/:projectId',
    element: <ReportPage />,
  },
  {
    path: '/rr/:screenNumber/:screenVariantNumber/:screenVariantFragmentNumber/:projectId',
    element: <ReportPage />,
  },
  {
    path: '/rr/:screenNumber/:screenVariantNumber/:screenVariantFragmentNumber/:assessmentId/:projectId',
    element: <ReportPage />,
  },
  {
    path: '/rrr/:projectId',
    element: <ReportPage />,
  },
  {
    path: '/rrr/:screenNumber/:projectId',
    element: <ReportPage />,
  },
  {
    path: '/rrr/:screenNumber/:screenVariantNumber/:projectId',
    element: <ReportPage />,
  },
  {
    path: '/rrr/:screenNumber/:screenVariantNumber/:screenVariantFragmentNumber/:projectId',
    element: <ReportPage />,
  },
  {
    path: '/rrr/:screenNumber/:screenVariantNumber/:screenVariantFragmentNumber/:assessmentId/:projectId',
    element: <ReportPage />,
  },
  {
    path: '/rt/:projectId',
    element: <ReportPage isTotalAssessment />,
  },
  {
    path: '/rt/:screenNumber/:projectId',
    element: <ReportPage isTotalAssessment />,
  },
  {
    path: '/rt/:screenNumber/:screenVariantNumber/:projectId',
    element: <ReportPage isTotalAssessment />,
  },
  {
    path: '/rt/:screenNumber/:screenVariantNumber/:screenVariantFragmentNumber/:projectId',
    element: <ReportPage isTotalAssessment />,
  },
  {
    path: '/rt/:screenNumber/:screenVariantNumber/:screenVariantFragmentNumber/:assessmentId/:projectId',
    element: <ReportPage isTotalAssessment />,
  },
  {
    path: '/rrt/:projectId',
    element: <ReportPage isTotalAssessment />,
  },
  {
    path: '/rrt/:screenNumber/:projectId',
    element: <ReportPage isTotalAssessment />,
  },
  {
    path: '/rrt/:screenNumber/:screenVariantNumber/:projectId',
    element: <ReportPage isTotalAssessment />,
  },
  {
    path: '/rrt/:screenNumber/:screenVariantNumber/:screenVariantFragmentNumber/:projectId',
    element: <ReportPage isTotalAssessment />,
  },
  {
    path: '/rrt/:screenNumber/:screenVariantNumber/:screenVariantFragmentNumber/:assessmentId/:projectId',
    element: <ReportPage isTotalAssessment />,
  },
  {
    path: '/rm/:projectId',
    element: <ReportPage />,
  },
])

export const Routes = () => <RouterProvider router={router} />
