import axios from 'axios'

export const axiosInstance = axios.create({
  withCredentials: true,
  baseURL:
    process.env.NODE_ENV === 'development'
      ? undefined
      : 'https://backend.prodmaps.com',
})

axiosInstance.interceptors.request.use((config) => {
  config.headers['X-CSRF-Token'] = localStorage.getItem('csrf-token')

  return config
})
