import classnames from 'classnames'
import { useDropzone } from 'react-dropzone'
import {
  SCREEN_HEIGHT,
  SCREEN_WIDTH,
  useAddScreensFromFiles,
  useUpdateScreenName,
  FlowScreenNodeProps,
  DEFAULT_OFFSET,
  useAddColumnToRightOfScreen,
  useAddScreenWithTransitionInRow,
  useDeletePair,
  useScreenHasLeftTransition,
  useUpdateImageOnScreen,
} from 'entities/flow'
import { useEffect, useState } from 'react'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import { Handle, Position, ReactFlowState, useStore } from 'reactflow'
import { CircularProgress, IconButton, Tooltip, styled } from '@mui/material'
import { blue } from '@mui/material/colors'
import { useNavigate } from 'react-router-dom'
import { useProject, useProjectId } from 'shared/model/projects'

import styles from './FlowScreenNode.module.css'
import { useHasWritePermission } from 'entities/permissions'
import i18n from 'shared/i18n/i18n'
import { useProjectContext } from 'features/head/context'

import { ReactComponent as PictureSvg } from './picture.svg'
import { ReportAssessmentType } from 'widgets/report/types'
import { ProjectData } from 'shared/types/projects'
import { getReportAssessmentAttributes } from 'entities/assessment'

const zoomSelector = (state: ReactFlowState) => state.transform[2]

const StyledIconButton = styled(IconButton)({
  '&:hover': {
    backgroundColor: blue[500],
    '& svg': {
      fill: '#fff',
    },
  },
})

const getScreenAssessments = (
  project: ProjectData,
  screenId: string
): Record<ReportAssessmentType, boolean> => {
  const fragments = project.screenFragments[screenId]

  if (fragments) {
    return {
      'ux-good': fragments.some((fragment) =>
        fragment.assessments.some(
          (assessment) => assessment.findType === 'ux-good'
        )
      ),
      'ux-problem-high': fragments.some((fragment) =>
        fragment.assessments.some(
          (assessment) =>
            assessment.findType === 'ux-problem' && assessment.type === 'bad'
        )
      ),
      'ux-problem-medium': fragments.some((fragment) =>
        fragment.assessments.some(
          (assessment) =>
            assessment.findType === 'ux-problem' && assessment.type === 'medium'
        )
      ),
      'ux-problem-low': fragments.some((fragment) =>
        fragment.assessments.some(
          (assessment) =>
            assessment.findType === 'ux-problem' && assessment.type === 'good'
        )
      ),
      task: false,
      bug: false,
    }
  }

  return {
    'ux-good': false,
    'ux-problem-high': false,
    'ux-problem-medium': false,
    'ux-problem-low': false,
    task: false,
    bug: false,
  }
}

const findTypes: ReportAssessmentType[] = [
  'ux-good',
  'ux-problem-low',
  'ux-problem-medium',
  'ux-problem-high',
]

const reportAttributes = getReportAssessmentAttributes()

export const FlowScreenNode = ({
  data,
  xPos,
  yPos,
  id,
}: FlowScreenNodeProps) => {
  const hasWritePermission = useHasWritePermission()

  const [isLoading, setLoading] = useState(false)
  const navigate = useNavigate()
  const projectId = useProjectId()
  const zoom = useStore(zoomSelector)

  const isCreate = !data.name
  const [name, setName] = useState('')

  const updateScreenName = useUpdateScreenName()
  const addColumnToRightOfScreen = useAddColumnToRightOfScreen()

  const handleNameChange = () => {
    hasWritePermission &&
      updateScreenName({
        id,
        name,
      })
  }

  useEffect(() => {
    setName(data.name || '')
  }, [data.name])

  const addScreensFromFiles = useAddScreensFromFiles()
  const updateImageOnScreen = useUpdateImageOnScreen()
  const addScreenWithTransitionInRow = useAddScreenWithTransitionInRow()
  const deletePair = useDeletePair()

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: async (files) => {
      try {
        setLoading(true)

        if (data.previewImageUrl) {
          await updateImageOnScreen({
            file: files[0],
            screenId: id,
          })
        } else {
          await addScreensFromFiles({
            files: files.sort(
              (file1, file2) => file1.lastModified - file2.lastModified
            ),
            position: {
              x: xPos,
              y: yPos,
            },
            firstScreenId: id,
          })
        }
      } finally {
        setLoading(false)
      }
    },
    accept: {
      'image/jpeg': [],
      'image/png': [],
    },
    maxFiles: data.previewImageUrl ? 1 : Infinity,
  })

  const hasLeftTransition = useScreenHasLeftTransition({
    x: xPos,
    y: yPos,
  })

  const calculatedFontSize = Math.round(14 / zoom)
  const inputFontSize = calculatedFontSize >= 16 ? calculatedFontSize : 16

  const { isMaster } = useProjectContext()
  const { data: project } = useProject()

  const isWritable = isMaster

  const screenAssessments = project
    ? getScreenAssessments(project, id)
    : ({} as Record<ReportAssessmentType, boolean>)

  const screenFindTypesNotEmptyArray = Object.keys(screenAssessments).filter(
    (key) => screenAssessments[key as ReportAssessmentType]
  )

  const notEmptyFindTypes = findTypes.filter((findType) =>
    screenFindTypesNotEmptyArray.includes(findType)
  )

  return (
    <>
      <Handle
        style={{
          opacity: 0,
        }}
        type="target"
        position={Position.Left}
      />
      <div className={styles.wrapper}>
        {isLoading && (
          <div className={styles.loader}>
            <CircularProgress size={100} />
          </div>
        )}
        <input
          onBlur={handleNameChange}
          onKeyDown={(event) => {
            if (event.code === 'Enter' || event.code === 'NumpadEnter') {
              event.currentTarget.blur()
            }
          }}
          className={classnames(styles.input, {
            [styles['input-empty']]: isCreate,
          })}
          style={{
            fontSize: inputFontSize,
            top: -inputFontSize - 3,
          }}
          onChange={(e) =>
            hasWritePermission && isWritable
              ? setName(e.target.value)
              : undefined
          }
          placeholder={`+ ${i18n.t('name')}`}
          type="text"
          name="screen-name"
          autoFocus
          value={name}
        />
        <div
          className={classnames(styles.inner, {
            [styles['inner-empty']]: isCreate,
            [styles.innerNoImage]: !data.previewImageUrl,
          })}
          style={{
            height: SCREEN_HEIGHT,
            width: SCREEN_WIDTH,
            backgroundImage: data.previewImageUrl
              ? `url(${data.previewImageUrl})`
              : undefined,
            backgroundColor: data.previewImageUrl ? 'gray' : undefined,
          }}
          {...(hasWritePermission && isWritable && getRootProps())}
          onClick={
            data.previewImageUrl
              ? () => {
                  const url = hasWritePermission
                    ? `screen/${id}`
                    : `/project/${projectId}/screen/${id}`

                  navigate(url)
                }
              : getRootProps().onClick
          }
        >
          {hasWritePermission && isWritable && <input {...getInputProps()} />}
          {!data.previewImageUrl && hasWritePermission && isWritable && (
            <div className={styles.dragImageLabelWrapper}>
              <PictureSvg />
              <span className={styles.dragImageLabel}>+ drag img here</span>
            </div>
          )}
        </div>
        {hasWritePermission && isWritable && (
          <div
            onClick={(event) => {
              event.stopPropagation()
              addColumnToRightOfScreen({
                fromPositionX: xPos,
              })
            }}
            className={styles.addColumnWrapper}
          >
            <div className={styles.addColumnHighlight} />
            <div className={styles.addColumn}>← {i18n.t('addColumn')} →</div>
          </div>
        )}

        {hasWritePermission && isWritable && (
          <div className={styles.addPairWrapper}>
            <div className={styles.addPairHighlight} />
            <Tooltip
              className={styles.tooltip}
              placement="top"
              title={i18n.t('addPair')}
            >
              <StyledIconButton
                onClick={(event) => {
                  event.stopPropagation()
                  addScreenWithTransitionInRow({
                    position: {
                      y: yPos,
                      x: xPos + SCREEN_WIDTH + DEFAULT_OFFSET,
                    },
                  })
                }}
                size="medium"
                className={styles.addIcon}
              >
                <AddIcon />
              </StyledIconButton>
            </Tooltip>

            {hasLeftTransition && (
              <Tooltip
                className={styles.tooltip}
                placement="bottom"
                title={i18n.t('removePair')}
              >
                <StyledIconButton
                  onClick={(event) => {
                    event.stopPropagation()
                    deletePair({
                      screenPosition: {
                        y: yPos,
                        x: xPos,
                      },
                    })
                  }}
                  size="medium"
                  className={styles.deleteIcon}
                >
                  <DeleteIcon />
                </StyledIconButton>
              </Tooltip>
            )}
          </div>
        )}
      </div>
      {notEmptyFindTypes.length > 0 && (
        <div className={styles.screenAssessmentsWrapper}>
          {notEmptyFindTypes.map((findType, index) => (
            <div
              style={{
                zIndex: 10 - index,
                left: 18 * index - 10,
                backgroundColor: reportAttributes[findType].color,
              }}
              className={styles.screenAssessment}
            />
          ))}
        </div>
      )}
    </>
  )
}
