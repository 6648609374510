import { Checkbox, Dialog, FormControlLabel, FormGroup } from '@mui/material'
import { PropsWithChildren, createContext, useContext, useState } from 'react'
import { useCSRF } from 'shared/api/projects'

interface ProjectContextValues {
  isMaster: boolean
  isAssessment: boolean
  toggleMenu: VoidFunction
  setAssessment: (value: boolean) => void
  funPassed: boolean
  setFunPassed: (value: boolean) => void
}

export const ProjectContext = createContext<ProjectContextValues>({
  isMaster: true,
  isAssessment: false,
  toggleMenu: () => {},
  setAssessment: () => {},
  funPassed: false,
  setFunPassed: () => null,
})

export const ProjectProvider = (props: PropsWithChildren) => {
  const isReport =
    window.location.pathname.includes('/r/') ||
    window.location.pathname.includes('/rr/') ||
    window.location.pathname.includes('/rrr/')
  const isReportBundling =
    window.location.pathname.includes('/rt/') ||
    window.location.pathname.includes('/rrt/')

  const isNewMaster =
    window.location.pathname.includes('/aa/') ||
    window.location.pathname.includes('/rr/')

  const [isMenuOpen, setMenuOpen] = useState(false)
  const [funPassed, setFunPassed] = useState(false)
  const [isMaster, setMaster] = useState(isReportBundling || isNewMaster)
  const [isAssessment, setAssessment] = useState(!isReport || isReportBundling)
  const toggleMenu = () => setMenuOpen((prevState) => !prevState)

  const tokenFetched = useCSRF()

  if (!tokenFetched) {
    return <div />
  }

  const isOldSchema =
    window.location.pathname.includes('/a/') ||
    window.location.pathname.includes('/r/')

  return (
    <>
      <ProjectContext.Provider
        value={{
          toggleMenu,
          isAssessment,
          isMaster,
          setAssessment,
          funPassed,
          setFunPassed,
        }}
      >
        {props.children}
        {isMenuOpen && isOldSchema && (
          <Dialog open onClose={toggleMenu}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isMaster}
                    onChange={(e) => setMaster(e.target.checked)}
                  />
                }
                label="Мастер сценарий"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isAssessment}
                    onChange={(e) => {
                      setAssessment(e.target.checked)

                      if (e.target.checked && isMaster) {
                        window.location.href = window.location.href
                          .replace('/a/', '/rt/')
                          .replace('/r/', '/rt/')
                      }
                    }}
                  />
                }
                label="Оценка"
              />
            </FormGroup>
          </Dialog>
        )}
      </ProjectContext.Provider>
    </>
  )
}

export const useProjectContext = () => useContext(ProjectContext)
