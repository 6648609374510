import {
  Assessment,
  AssessmentFilterType,
  FilterAttribute,
  HeuristicFilterType,
  getAssessmentAttributes,
  getReportAssessmentAttributes,
} from 'entities/assessment'
import StarIcon from '@mui/icons-material/Star'
import i18n from 'shared/i18n/i18n'
import { ReportAssessmentType, ReportAssessments } from 'widgets/report/types'
import { UseTranslationResponse } from 'react-i18next'

export const getHeuristicFilterAttributes =
  (): FilterAttribute<HeuristicFilterType>[] => [
    {
      type: 'all',
      label: i18n.t('all'),
    },
    {
      type: 'favorite',
      label: <StarIcon />,
    },
  ]

const reportAssessmentAttributes = getReportAssessmentAttributes()

export const getAssessmentFilterAttributes = (
  assessments: Assessment[]
): FilterAttribute<ReportAssessmentType | 'all'>[] => [
  {
    type: 'all',
    label: assessments.length,
  },
  {
    type: 'ux-good',
    label: assessments.filter((item) => item.findType === 'ux-good').length,
    color: reportAssessmentAttributes['ux-good'].color,
    icon: reportAssessmentAttributes['ux-good'].icon,
  },
  {
    type: 'ux-problem-low',
    label: assessments.filter(
      (item) => item.findType === 'ux-problem' && item.type === 'good'
    ).length,
    color: reportAssessmentAttributes['ux-problem-low'].color,
    icon: reportAssessmentAttributes['ux-problem-low'].icon,
  },
  {
    type: 'ux-problem-medium',
    label: assessments.filter(
      (item) => item.findType === 'ux-problem' && item.type === 'medium'
    ).length,
    color: reportAssessmentAttributes['ux-problem-medium'].color,
    icon: reportAssessmentAttributes['ux-problem-medium'].icon,
  },
  {
    type: 'ux-problem-high',
    label: assessments.filter(
      (item) => item.findType === 'ux-problem' && item.type === 'bad'
    ).length,
    color: reportAssessmentAttributes['ux-problem-high'].color,
    icon: reportAssessmentAttributes['ux-problem-high'].icon,
  },
  {
    type: 'task',
    label: assessments.filter((item) => item.findType === 'task').length,
    color: reportAssessmentAttributes.task.color,
    icon: reportAssessmentAttributes.task.icon,
  },
  {
    type: 'bug',
    label: assessments.filter((item) => item.findType === 'bug').length,
    color: reportAssessmentAttributes.bug.color,
    icon: reportAssessmentAttributes.bug.icon,
  },
]

export const getAssessmentReportFilterAttributes = (
  assessments: ReportAssessments,
  t: (value: string) => string
): FilterAttribute<ReportAssessmentType | 'all'>[] => [
  {
    type: 'all',
    label:
      assessments.bug +
      assessments.task +
      assessments['ux-good'] +
      assessments['ux-problem-high'] +
      assessments['ux-problem-low'] +
      assessments['ux-problem-medium'],
  },
  {
    type: 'ux-good',
    label: assessments['ux-good'],
    color: reportAssessmentAttributes['ux-good'].color,
    icon: reportAssessmentAttributes['ux-good'].icon,
    tooltip: t('usabilityGood'),
  },
  {
    type: 'ux-problem-low',
    label: assessments['ux-problem-low'],
    color: reportAssessmentAttributes['ux-problem-low'].color,
    icon: reportAssessmentAttributes['ux-problem-low'].icon,
    tooltip: `${t('usabilityProblem')} (${t('criticality')}: ${t('low')})`,
  },
  {
    type: 'ux-problem-medium',
    label: assessments['ux-problem-medium'],
    color: reportAssessmentAttributes['ux-problem-medium'].color,
    icon: reportAssessmentAttributes['ux-problem-medium'].icon,
    tooltip: `${t('usabilityProblem')} (${t('criticality')}: ${t('medium')})`,
  },
  {
    type: 'ux-problem-high',
    label: assessments['ux-problem-high'],
    color: reportAssessmentAttributes['ux-problem-high'].color,
    icon: reportAssessmentAttributes['ux-problem-high'].icon,
    tooltip: `${t('usabilityProblem')} (${t('criticality')}: ${t('high')})`,
  },
  {
    type: 'task',
    label: assessments.task,
    color: reportAssessmentAttributes.task.color,
    icon: reportAssessmentAttributes.task.icon,
    tooltip: t('businessTask'),
  },
  {
    type: 'bug',
    label: assessments.bug,
    color: reportAssessmentAttributes.bug.color,
    icon: reportAssessmentAttributes.bug.icon,
    tooltip: t('bug'),
  },
]
