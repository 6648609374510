import { Tab, Tabs, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import s from './ProjectHead.module.css'
import { ScenarioInfo } from 'widgets/report/ui/ScenarioInfo'
import { useProjectId } from 'shared/model/projects'
import { useProjectContext } from './context'
import { useLocation, useNavigate } from 'react-router-dom'
import MenuIcon from '@mui/icons-material/Menu'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { useUserInfoQuery } from './model'

export const ProjectHead = () => {
  const projectId = useProjectId()

  const { t } = useTranslation()

  const { isMaster, isAssessment, toggleMenu, setAssessment } =
    useProjectContext()

  const navigate = useNavigate()
  const { pathname } = useLocation()

  const masterScenarioTabsValue = !isAssessment
    ? 'masterScenario'
    : 'finalAssessment'

  const isNewMaster =
    pathname.includes('/aa/') ||
    pathname.includes('/rr/') ||
    pathname.includes('/rrt/')

  const isNewChild = pathname.includes('/aaa/') || pathname.includes('/rrr')

  const { data: userInfo } = useUserInfoQuery()

  return (
    <div className={s.wrapper}>
      <div className={s.left}>
        <div className={s.menuIconWrapper}>
          <MenuIcon fontSize="small" />
        </div>
        {projectId && (
          <ScenarioInfo withoutBottomBorder projectId={projectId}>
            {isMaster ? (
              <Tabs
                classes={{
                  root: s.tabs,
                  flexContainer: s.tabs,
                }}
                value={masterScenarioTabsValue}
                onChange={(_, newTab) => {
                  if (newTab === 'masterScenario') {
                    setAssessment(false)

                    if (isNewMaster) {
                      navigate(`/aa/${projectId}`)
                    } else if (isNewChild) {
                      navigate(`/aaa/${projectId}`)
                    } else {
                      navigate(`/a/${projectId}`)
                    }
                  } else {
                    if (isNewMaster) {
                      navigate(`/rrt/${projectId}`)
                    } else {
                      navigate(`/rt/${projectId}`)
                    }
                    setAssessment(true)
                  }
                }}
              >
                <Tab
                  value="masterScenario"
                  label={t('masterScenario')}
                  classes={{
                    selected: s.selectedTab,
                    root: s.tab,
                  }}
                />
                <Tab
                  value="finalAssessment"
                  label={t('finalAssessment')}
                  classes={{
                    selected: s.selectedTab,
                    root: s.tab,
                  }}
                />
              </Tabs>
            ) : (
              <Tabs
                classes={{
                  root: s.tabs,
                  flexContainer: s.tabs,
                }}
                value={
                  pathname.includes('/a/') ||
                  pathname.includes('/aa/') ||
                  pathname.includes('/aaa/') ||
                  pathname.includes('/screen/')
                    ? 'assessment'
                    : 'report'
                }
                onChange={(_, newTab) => {
                  if (newTab === 'assessment') {
                    if (isNewMaster) {
                      navigate(`/aa/${projectId}`)
                    } else if (isNewChild) {
                      navigate(`/aaa/${projectId}`)
                    } else {
                      navigate(`/a/${projectId}`)
                    }
                    setAssessment(true)
                  } else {
                    if (isNewMaster) {
                      navigate(`/rr/${projectId}`)
                    } else if (isNewChild) {
                      navigate(`/rrr/${projectId}`)
                    } else {
                      navigate(`/r/${projectId}`)
                    }
                    setAssessment(false)
                  }
                }}
              >
                <Tab
                  value="assessment"
                  label={t('assessment')}
                  classes={{
                    selected: s.selectedTab,
                    root: s.tab,
                  }}
                />
                <Tab
                  value="report"
                  label={t('report')}
                  classes={{
                    selected: s.selectedTab,
                    root: s.tab,
                  }}
                />
              </Tabs>
            )}
          </ScenarioInfo>
        )}
      </div>
      <div onClick={toggleMenu} className={s.right}>
        <div>
          <Typography variant="body2">
            {userInfo?.display_name || ''}
          </Typography>
          <Typography className={s.email} variant="body2">
            {userInfo?.mail || ''}
          </Typography>
        </div>
        <div className={s.profileIconWrapper}>
          <AccountCircleIcon fontSize="small" />
        </div>
      </div>
    </div>
  )
}
