import { Assessment, useHeuristics } from 'entities/assessment'

import s from './ReportFindDetails.module.css'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { getReportAssessmentFilterOptions } from 'features/assessment/find/lib'
import { ReportScreenCanvas } from '../ReportScreenCanvas'
import { Report } from 'widgets/report/types'
import { useProject } from 'shared/model/projects'
import { useEffect, useState } from 'react'
import { ReportDetailsContent } from './ReportDetailsContent'
import classNames from 'classnames'
import { getParagraphsFromString } from 'shared/lib/string'
import { ReportDetailsHeuristicInfo } from './ReportDetailsHeuristicInfo'

const typeToCriticalityMap: Record<string, string> = {
  bad: 'high',
  medium: 'medium',
  good: 'low',
}

interface ReportFindDetailsProps {
  assessment: Assessment
  number: number
  screenId: string
  fragmentId: string
  report: Report
  recommendation: any
  heuristicObject: any
  className?: string
}

export const ReportFindDetails = ({
  assessment,
  number,
  fragmentId,
  report,
  screenId,
  recommendation,
  heuristicObject,
  className,
}: ReportFindDetailsProps) => {
  const { t } = useTranslation()
  const [activeHeuristicId, setActiveHeuristicId] = useState('')

  const { data: project } = useProject()

  const heuristics = useHeuristics({
    tags: [],
    projectId: project?.id,
  })

  useEffect(() => {
    setActiveHeuristicId('')
  }, [assessment.id])

  const heuristic = heuristics?.find(
    (item) => item.id === assessment.heuristicId
  )

  useEffect(() => {
    const onKeyDownHandle = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        if (activeHeuristicId) {
          setActiveHeuristicId('')
        }
      }
    }

    document.addEventListener('keydown', onKeyDownHandle)

    return () => document.removeEventListener('keydown', onKeyDownHandle)
  }, [activeHeuristicId])

  const reportAssessmentFilterOptions = getReportAssessmentFilterOptions()
  const filterOptionKey =
    assessment.findType === 'ux-problem'
      ? `ux-problem-${typeToCriticalityMap[assessment.type]}`
      : assessment.findType
  const reportAssessmentFilterOption = reportAssessmentFilterOptions.find(
    (item) => item.value === filterOptionKey
  )

  return (
    <div className={classNames(s.wrapper, className)}>
      <div className={s.findForm}>
        <Typography className={s.subtitle}>{t('findName')}</Typography>
        <Typography className={s.bigText}>{assessment.name}</Typography>

        <Typography className={s.subtitle}>{t('findType')}</Typography>
        {reportAssessmentFilterOption && (
          <div className={s.findTypeWrapper}>
            <div
              style={{
                backgroundColor: reportAssessmentFilterOption.color,
              }}
              className={s.findTypeIconWrapper}
            >
              {reportAssessmentFilterOption.icon}
            </div>
            <div>
              <Typography className={s.findTypeTitle}>
                {assessment.findType === 'ux-problem'
                  ? t('usabilityProblem')
                  : reportAssessmentFilterOption.name}
              </Typography>
              {assessment.findType === 'ux-problem' && (
                <Typography className={s.findTypeSubtitle}>
                  {t(typeToCriticalityMap[assessment.type])}{' '}
                  {t('criticality').toLowerCase() || ''}
                </Typography>
              )}
            </div>
          </div>
        )}

        {!!assessment.heuristicId && !!heuristic && (
          <ReportDetailsHeuristicInfo
            heuristic={heuristic}
            onOpenDetails={() => setActiveHeuristicId(assessment.heuristicId)}
            isNegative={assessment.findType === 'ux-problem'}
          />
        )}

        {assessment.comment && (
          <>
            <Typography className={s.subtitle}>{t('description')}</Typography>
            <Typography className={s.text}>
              {getParagraphsFromString(assessment.comment)?.map(
                (paragraph, index) => (
                  <>
                    {index !== 0 && (
                      <>
                        <br />
                        <br />
                      </>
                    )}
                    {paragraph}
                  </>
                )
              )}
            </Typography>
          </>
        )}

        {assessment.recommendation && (
          <>
            <Typography className={s.subtitle}>
              {t('recommendation')}
            </Typography>
            <Typography className={s.text}>
              {getParagraphsFromString(assessment.recommendation)?.map(
                (paragraph, index) => (
                  <>
                    {index !== 0 && (
                      <>
                        <br />
                        <br />
                      </>
                    )}
                    {paragraph}
                  </>
                )
              )}
            </Typography>
          </>
        )}
      </div>

      {activeHeuristicId && project?.id && (
        <ReportDetailsContent
          heuristic={heuristicObject}
          recommendation={recommendation}
          projectId={project?.id}
          className={s.heuristicInfo}
          onClose={() => setActiveHeuristicId('')}
        />
      )}

      {project && (
        <ReportScreenCanvas
          screenId={screenId}
          fragmentId={fragmentId}
          assessmentId={assessment?.id}
          project={project}
          report={report}
        />
      )}
    </div>
  )
}
