import { useQuery } from '@tanstack/react-query'
import { axiosInstance } from 'shared/api/axios'

interface Schema {
  meta: {
    links: {
      me: {
        meta: {
          id: string
        }
      }
    }
  }
}

interface UserInfo {
  data: {
    attributes: {
      display_name: string
      mail: string
    }
  }
}

export const useUserInfoQuery = () => {
  const query = useQuery({
    queryKey: ['user'],
    staleTime: Infinity,
    queryFn: async () => {
      const schema = await axiosInstance.get<Schema>('/en/api135a/')

      if (schema.data.meta.links.me.meta.id) {
        const user = await axiosInstance.get<UserInfo>(
          `/en/api135a/user/user/${schema.data.meta.links.me.meta.id}`
        )

        if (user.data.data.attributes.display_name) {
          return user.data.data.attributes
        }
      }
    },
  })

  return query
}
