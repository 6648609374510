import { QueryClient } from '@tanstack/react-query'
import { persistQueryClient } from '@tanstack/react-query-persist-client'
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: Infinity,
      refetchOnWindowFocus: false,
    },
  },
})

// const localStoragePersister = createSyncStoragePersister({
//   storage: window.localStorage,
// })

// persistQueryClient({
//   queryClient: queryClient as any,
//   persister: localStoragePersister,
// })
