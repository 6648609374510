import { getProjectType } from 'shared/lib/projects'
import { axiosInstance } from './axios'

interface UploadImageAndLinkWithProjectParams {
  file: File
  projectId: string
  onError: (message: string) => void
}

interface Data {
  id: string
  attributes: {
    uri: {
      url: string
    }
  }
}

interface UploadImageResponse {
  data: Data | Data[]
}

export const uploadImageAndLinkWithProject = async ({
  file,
  projectId,
  onError,
}: UploadImageAndLinkWithProjectParams) => {
  const fileBuffer = await file.arrayBuffer()

  const projectType = getProjectType()

  try {
    const uploadUrl =
      projectType === 'master'
        ? `/api135a/group/scenariy/${projectId}/field_g_project_images`
        : '/api135a/node/project/field_project_images'

    const uploadResponse = await axiosInstance.post<UploadImageResponse>(
      uploadUrl,
      fileBuffer,
      {
        headers: {
          Accept: 'application/vnd.api+json',
          'Content-Type': 'application/octet-stream',
          'Content-Disposition': `file; filename="${encodeURIComponent(
            file.name
          )}"`,
        },
      }
    )

    const imageIndex = Array.isArray(uploadResponse.data.data)
      ? uploadResponse.data.data.length - 1
      : 0

    const imageId = Array.isArray(uploadResponse.data.data)
      ? uploadResponse.data.data[imageIndex]?.id
      : uploadResponse.data.data.id

    const imageRelativeUrl = Array.isArray(uploadResponse.data.data)
      ? uploadResponse.data.data[imageIndex]?.attributes.uri.url
      : uploadResponse.data.data.attributes.uri.url

    try {
      if (!projectType) {
        await axiosInstance.post(
          `/api135a/node/project/${projectId}/relationships/field_project_images`,
          {
            data: [
              {
                type: 'file--file',
                id: imageId,
                meta: {
                  description: file.name,
                },
              },
            ],
          },
          {
            headers: {
              'Content-Type': 'application/vnd.api+json',
              Accept: 'application/vnd.api+json',
            },
          }
        )
      }

      return {
        imageUrl: `https://backend.prodmaps.com${imageRelativeUrl}`,
      }
    } catch (e) {
      onError('Произошла ошибка при связывании изображения и проекта')

      throw e
    }
  } catch (e) {
    onError('Произошла ошибка при загрузке изображения на сервер')

    throw e
  }
}
