import { ProjectData, RemoteProjectMeta } from 'shared/types/projects'
import { axiosInstance } from './axios'
import {
  getProjectKey,
  getProjectType,
  getTimestamp,
  isValidProjectData,
} from 'shared/lib/projects'
import { createUUID } from 'shared/lib/uuid'
import { getInitialFlow } from 'entities/flow/fixtures'
import { getDefaultRole } from 'entities/flow'
import { getInitialScreenFragments } from 'entities/assessment'
import { OLDProjectResponseData } from './projects-api-types'
import i18n from 'shared/i18n/i18n'
import { isEnglishProject } from 'shared/lib/language'
import axios from 'axios'
import {
  Included,
  MasterProjectResponseData,
} from './master-projects-api-types'
import { useEffect, useState } from 'react'
import { useSnackbar } from 'notistack'
import { ChildProjectResponseData } from './child-projects-api-types'

export const useCSRF = () => {
  const { enqueueSnackbar } = useSnackbar()
  const [tokenFetched, setTokenFetched] = useState(false)

  useEffect(() => {
    axiosInstance
      .get<string>('/session/token')
      .then((response) => {
        if (response.data) {
          localStorage.setItem('csrf-token', response.data)
          setTokenFetched(true)
        } else {
          throw new Error('token is empty')
        }
      })
      .catch(() =>
        enqueueSnackbar('token fetching is failed, please try later', {
          variant: 'error',
        })
      )
  }, [])

  return tokenFetched
}

interface GetInitialProjectDataParams {
  projectId: string
}

export const getInitialProjectData = ({
  projectId,
}: GetInitialProjectDataParams): ProjectData => {
  const intitialScreenId = createUUID()

  return {
    id: projectId,
    flow: getInitialFlow(intitialScreenId),
    roles: [getDefaultRole()],
    screenFragments: {
      [intitialScreenId]: getInitialScreenFragments(),
    },
    transitionComponents: [],
    saveTimestamp: getTimestamp(),
  }
}

interface UpdateProjectParams {
  projectId: string | undefined
  data: ProjectData
  updateTimestamp?: boolean
}

export const updateProject = async ({
  data,
  projectId,
  updateTimestamp = true,
}: UpdateProjectParams) => {
  if (!projectId) {
    throw new Error('Empty projectId')
  }

  const newData: ProjectData = updateTimestamp
    ? {
        ...data,
        saveTimestamp: getTimestamp(),
      }
    : data

  localStorage.setItem(getProjectKey(projectId), JSON.stringify(newData))
}
interface OLDUpdateRemoteProjectRequest {
  data: {
    type: 'projects'
    id: string
    attributes: {
      field_project_data: string
    }
  }
}

interface MasterUpdateRemoteProjectRequest {
  data: {
    type: 'group--scenariy'
    id: string
    attributes: {
      field_g_project_data: string
    }
  }
}

interface ChildUpdateRemoteProjectRequest {
  data: {
    type: 'group--child_scenariy'
    id: string
    attributes: {
      field_g_project_data: string
    }
  }
}

interface UpdateRemoteProjectParams {
  projectId: string | undefined
  data: ProjectData
  onError: (message: string) => void
  signal?: AbortSignal
}

export const updateRemoteProject = async ({
  data,
  projectId,
  signal,
  onError,
}: UpdateRemoteProjectParams) => {
  if (!projectId) {
    throw new Error('Empty projectId')
  }

  const projectType = getProjectType()

  if (projectType) {
    try {
      if (projectType === 'master') {
        await axiosInstance.patch<void, any, MasterUpdateRemoteProjectRequest>(
          `/api135a/group/scenariy/${projectId}`,
          {
            data: {
              type: 'group--scenariy',
              id: projectId,
              attributes: {
                field_g_project_data: JSON.stringify(data),
              },
            },
          },
          {
            headers: {
              'Content-Type': 'application/vnd.api+json',
              Accept: 'application/vnd.api+json',
            },
            signal,
          }
        )
      }
      if (projectType === 'child') {
        await axiosInstance.patch<void, any, ChildUpdateRemoteProjectRequest>(
          `/api135a/group/child_scenariy/${projectId}`,
          {
            data: {
              type: 'group--child_scenariy',
              id: projectId,
              attributes: {
                field_g_project_data: JSON.stringify(data),
              },
            },
          },
          {
            headers: {
              'Content-Type': 'application/vnd.api+json',
              Accept: 'application/vnd.api+json',
            },
            signal,
          }
        )
      }
    } catch (e) {
      if (!axios.isCancel(e)) {
        onError(i18n.t('saveProjectError'))

        throw e
      }
    }
  } else {
    try {
      await axiosInstance.patch<void, any, OLDUpdateRemoteProjectRequest>(
        `/en/api135a/node/project/${projectId}`,
        {
          data: {
            type: 'projects',
            id: projectId,
            attributes: {
              field_project_data: JSON.stringify(data),
            },
          },
        },
        {
          headers: {
            'Content-Type': 'application/vnd.api+json',
            Accept: 'application/vnd.api+json',
          },
          signal,
        }
      )
    } catch (e) {
      if (!axios.isCancel(e)) {
        onError(i18n.t('saveProjectError'))

        throw e
      }
    }
  }
}

interface FetchProjectParams {
  projectId: string | undefined
}

export const fetchProject = async ({
  projectId,
}: FetchProjectParams): Promise<ProjectData | null> => {
  if (!projectId) {
    throw new Error('Empty projectId')
  }

  const projectData = localStorage.getItem(getProjectKey(projectId))

  if (!projectData) {
    return null
  }

  const parsedProjectData: ProjectData = JSON.parse(projectData)

  const isEnglish = isEnglishProject(projectId)

  i18n.changeLanguage(isEnglish ? 'en' : 'ru')

  return parsedProjectData
}

interface OLDFetchRemoteProjectResponse {
  data: OLDProjectResponseData
}

interface MasterFetchRemoteProjectResponse {
  data: MasterProjectResponseData
  included: Included
}

interface ChildFetchRemoteProjectResponse {
  data: ChildProjectResponseData
}

interface FetchRemoteProjectParams {
  projectId: string | undefined
  onError?: (message: string) => void
  signal?: AbortSignal
  isLinkProject?: boolean
}

export const fetchRemoteProject = async ({
  projectId,
  signal,
  onError,
  isLinkProject = false,
}: // FIX ME (any)
FetchRemoteProjectParams): Promise<ProjectData | any> => {
  if (!projectId) {
    throw new Error('Empty projectId')
  }

  const projectType = getProjectType()

  if (projectType) {
    try {
      if (projectType === 'master') {
        const response =
          await axiosInstance.get<MasterFetchRemoteProjectResponse>(
            `/api135a/group/scenariy/${projectId}?include=field_prod_roles,field_prod_roles.entity_id&page=${getTimestamp()}`,
            {
              headers: {
                Accept: 'application/vnd.api+json',
              },
              signal,
            }
          )

        const parsedProjectData: ProjectData = JSON.parse(
          response.data.data.attributes.field_g_project_data
        )

        if (isValidProjectData(parsedProjectData)) {
          const projectMeta: RemoteProjectMeta = {
            platform: response.data.data.attributes.field_g_device,
            product: response.data.data.attributes.field_scenario_product,
            role: response.data.included?.[0].attributes.label,
            title: response.data.data.attributes.label,
            version: response.data.data.attributes.field_g_version,
            language: response.data.data.attributes.field_g_language,
          }

          localStorage.setItem(
            `metaData-${projectId}`,
            JSON.stringify(projectMeta)
          )

          return parsedProjectData
        } else {
          await updateRemoteProject({
            projectId,
            data: getInitialProjectData({ projectId }),
            onError: onError || (() => {}),
          })

          return fetchRemoteProject({ projectId, onError })
        }
      }
      if (projectType === 'child') {
        const response =
          await axiosInstance.get<ChildFetchRemoteProjectResponse>(
            `/api135a/group/child_scenariy/${projectId}/?page=${getTimestamp()}`,
            {
              headers: {
                Accept: 'application/vnd.api+json',
              },
              signal,
            }
          )

        const parsedChildProjectData: ProjectData = JSON.parse(
          response.data.data.attributes.field_g_project_data
        )

        if (isValidProjectData(parsedChildProjectData)) {
          const projectMeta: RemoteProjectMeta = {
            platform:
              response.data.data.attributes.field_chscenario_scenario_platfo,
            product: response.data.data.attributes.field_chscenario_product,
            role: response.data.data.attributes.field_chscenario_product_role,
            title: response.data.data.attributes.label,
            version:
              response.data.data.attributes.field_chscenario_scenario_ver,
            language:
              response.data.data.attributes.field_chscenario_scenario_lang,
          }

          localStorage.setItem(
            `metaData-${projectId}`,
            JSON.stringify(projectMeta)
          )

          if (response.data.data.attributes.field_chscenario_scen_uuid) {
            const masterScenarioResponse =
              await axiosInstance.get<MasterFetchRemoteProjectResponse>(
                `/api135a/group/scenariy/${
                  response.data.data.attributes.field_chscenario_scen_uuid
                }?include=field_prod_roles,field_prod_roles.entity_id&page=${getTimestamp()}`,
                {
                  headers: {
                    Accept: 'application/vnd.api+json',
                  },
                  signal,
                }
              )

            const parsedMasterProjectData: ProjectData = JSON.parse(
              masterScenarioResponse.data.data.attributes.field_g_project_data
            )

            if (masterScenarioResponse.data.data.attributes.label) {
              localStorage.setItem(
                `metaData-${projectId}`,
                JSON.stringify({
                  ...projectMeta,
                  title: masterScenarioResponse.data.data.attributes.label,
                })
              )
            }

            const masterScreens = parsedMasterProjectData.flow.screens
            const masterTransitions = parsedMasterProjectData.flow.transitions
            const childScreens = parsedChildProjectData.flow.screens

            if (
              masterScreens.some((masterScreen) =>
                childScreens.every(
                  (childScreen) => childScreen.id !== masterScreen.id
                )
              ) ||
              masterScreens.length !== childScreens.length
            ) {
              const newChildProjectData: ProjectData = {
                ...parsedChildProjectData,
                flow: {
                  ...parsedChildProjectData.flow,
                  screens: masterScreens,
                  transitions: masterTransitions,
                },
              }

              Object.keys(newChildProjectData.screenFragments).forEach(
                (childScreenId) => {
                  if (
                    masterScreens.every(
                      (masterScreen) => masterScreen.id !== childScreenId
                    )
                  ) {
                    delete newChildProjectData.screenFragments[childScreenId]
                  }
                }
              )

              const newScreens = masterScreens.filter((masterScreen) =>
                childScreens.every(
                  (childScreen) => childScreen.id !== masterScreen.id
                )
              )

              newScreens.forEach((newScreen) => {
                newChildProjectData.screenFragments[newScreen.id] =
                  parsedMasterProjectData.screenFragments[newScreen.id] ||
                  getInitialScreenFragments()
              })

              await updateRemoteProject({
                projectId,
                data: newChildProjectData,
                onError: onError || (() => {}),
              })

              return newChildProjectData
            }
          }

          return parsedChildProjectData
        } else {
          await updateRemoteProject({
            projectId,
            data: getInitialProjectData({ projectId }),
            onError: onError || (() => {}),
          })

          return fetchRemoteProject({ projectId, onError })
        }
      }
    } catch (e) {
      onError?.(i18n.t('updateProjectError'))

      throw e
    }
  } else {
    try {
      const response = await axiosInstance.get<OLDFetchRemoteProjectResponse>(
        `/en/api135a/node/project/${projectId}?include=field_scen_company,field_scen_product_parent,field_scen_roles&jsonapi_include=1&page=${getTimestamp()}`,
        {
          headers: {
            Accept: 'application/vnd.api+json',
          },
          signal,
        }
      )

      const parsedProjectData: ProjectData = JSON.parse(
        response.data.data.field_project_data
      )

      if (isValidProjectData(parsedProjectData)) {
        const projectMeta: RemoteProjectMeta = {
          platform: response.data.data.field_device,
          product: response.data.data.field_scen_product_parent.title,
          role: response.data.data.field_scen_roles.title,
          title: response.data.data.title,
          version: response.data.data.field_version,
          language: response.data.data.field_language,
        }

        localStorage.setItem(
          `metaData-${projectId}`,
          JSON.stringify(projectMeta)
        )

        if (
          Array.isArray(parsedProjectData.links) &&
          parsedProjectData.links.length > 0
        ) {
          const linkProjectPromises = parsedProjectData.links.map(
            (linkProjectId) =>
              fetchRemoteProject({
                projectId: linkProjectId,
                isLinkProject: true,
              })
          )
          const linkProjects = await Promise.all(linkProjectPromises)
          parsedProjectData.linkProjects = linkProjects
        }

        return parsedProjectData
      } else {
        await updateRemoteProject({
          projectId,
          data: getInitialProjectData({ projectId }),
          onError: onError || (() => {}),
        })

        return fetchRemoteProject({ projectId, onError })
      }
    } catch (e) {
      onError?.(i18n.t('updateProjectError'))

      throw e
    }
  }
}
